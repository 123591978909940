<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      flex
      align-center
      justify-center
    >
      <v-card width="350px">
        <v-card-title>
          Login
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              id="email"
              v-model="form.email"
              name="Email"
              type="email"
              required
              autofocus
              label="Email"
            />
            <v-text-field
              v-model="form.password"
              name="Password"
              label="Password"
              type="password"
              autocomplete="password"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            primary
            large
            block
            @click="submit"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      error: null
    }
  },
  methods: {
    ...mapActions(['login']),
    submit () {
      this.login({ email: this.form.email, password: this.form.password })
    }
  }
}
</script>
